import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Checkbox, Menu, Switch } from 'antd';
import 'antd/dist/antd.css';
import React, { useState } from 'react';
import { ReactComponent as DirectionsIcon } from './assets/directions.svg';
import { ReactComponent as CommunicationIcon } from './assets/kommunikationer.svg';
import { ReactComponent as LocationIcon } from './assets/location1.svg';
import { ReactComponent as MarkersIcon } from './assets/markers2.svg';
import { ReactComponent as Pin } from './assets/pin.svg';
import { ReactComponent as SatelliteIcon } from './assets/satellite_outlined.svg';
import { ReactComponent as ShopsIcon } from './assets/shop_outlined.svg';
import { ReactComponent as TiltIcon } from './assets/tilt.svg';
import * as consts from './consts';
import * as ga from './googleAnalytics';

const { SubMenu } = Menu;

function SideMenu({
  visibility,
  toggles,
  flyToClick,
  coordinates,
  pointsPerCity,
}) {
  const [collapsed, setCollapsed] = useState(false);
  const [openSubMenusKeys, setOpenSubMenusKeys] = useState([]);

  const onCityOpenChange = (openCityFromMenu) => {
    const clickedKey = openCityFromMenu.slice(-1);
    const isLastClickACity = Object.values(consts.citiesNames).includes(
      clickedKey[0]
    );
    const isClickedOnOpenKey = openSubMenusKeys.includes(clickedKey[0]);

    if (isClickedOnOpenKey) {
      setOpenSubMenusKeys(clickedKey);
    } else if (isLastClickACity) {
      setOpenSubMenusKeys([consts.CitiesKey, ...clickedKey]);
    } else {
      setOpenSubMenusKeys(clickedKey);
    }
  };

  const toggleCollapsed = () => {
    if (!collapsed) {
      ga.sendClickEvent(consts.analyticsNames.collapsed);
    }

    setCollapsed(!collapsed);
    if (visibility.directions) {
      toggles.directions();
    }
  };

  const isEmpty = (obj) => Object.keys(obj).length === 0;

  const buildAreasList = (cityName) => {
    if (isEmpty(coordinates)) return;
    const areas = Object.keys(coordinates[cityName]);

    return areas.map((area) => {
      return (
        <Menu.Item
          className="subMenuItem"
          key={`${cityName} ${area}`}
          onClick={() =>
            flyToClick(coordinates[cityName][area], ga.sendClickEvent(area))
          }
        >
          {area}
        </Menu.Item>
      );
    });
  };

  const getCityTitle = (cityName) =>
    `${cityName} (${isEmpty(pointsPerCity) ? '' : pointsPerCity[cityName]})`;

  const citisSubmenus = [
    {
      name: consts.citiesNames.Gothenburg,
      coordinates: consts.CitiesCoordinates.Gothenburg,
    },
    {
      name: consts.citiesNames.Stockholm,
      coordinates: consts.CitiesCoordinates.Stockholm,
    },
    {
      name: consts.citiesNames.Malmo,
      coordinates: consts.CitiesCoordinates.Malmo,
    },
    {
      name: consts.citiesNames.Uppsala,
      coordinates: consts.CitiesCoordinates.Uppsala,
    },
  ];

  const propertyFilters = [
    {
      key: '1',
      name: 'Alla',
      isVisible: visibility.allProperties,
      toggle: toggles.allProperties,
      color: 'blue',
    },
    {
      key: '2',
      name: 'Kontorsfastigheter',
      isVisible: visibility.offices,
      toggle: toggles.offices,
      color: 'darkBlue',
    },
    {
      key: '3',
      name: 'Handelsfastigheter',
      isVisible: visibility.commerce,
      toggle: toggles.commerce,
      color: 'orange',
    },
    {
      key: '4',
      name: 'Projektfastigheter',
      isVisible: visibility.projects,
      toggle: toggles.projects,
      color: 'pink',
    },
    {
      key: '5',
      name: 'Handelsstråk',
      isVisible: visibility.strok,
      toggle: toggles.strok,
      color: 'rectangle',
    },
    {
      key: '6',
      name: 'Område',
      isVisible: visibility.commercialArea,
      toggle: toggles.commercialArea,
      color: 'red',
    },
  ];

  const communicationFilters = [
    {
      key: '6',
      name: 'Kollektivtrafik',
      isVisible: visibility.publicTransport,
      toggle: toggles.publicTransport,
    },
    {
      key: '7',
      name: 'Huvudvägar',
      isVisible: visibility.roadsColor,
      toggle: toggles.roads,
    },
  ];

  const switches = [
    {
      isVisible: visibility.points,
      toggle: toggles.points,
      key: '10',
      name: 'Visa Markörer',
      iconComponent: <MarkersIcon />,
    },
    {
      isVisible: visibility.satellite,
      toggle: toggles.satellite,
      key: '11',
      name: 'Satellitvy',
      iconComponent: <SatelliteIcon />,
    },
    {
      isVisible: visibility.tilt,
      toggle: toggles.tilt,
      key: '12',
      name: 'Tilta kartan',
      iconComponent: <TiltIcon />,
    },
    {
      isVisible: visibility.shops,
      toggle: toggles.shops,
      key: '13',
      name: 'Butiker i närområdet',
      iconComponent: <ShopsIcon />,
    },
  ];

  const isAnyPropertySelected =
    visibility.allProperties ||
    visibility.projects ||
    visibility.commerce ||
    visibility.strok ||
    visibility.offices;

  return (
    <div className="menuRoot">
      <Button
        className="menuButton"
        type="primary"
        onClick={() => toggleCollapsed()}
        style={{ marginBottom: 16 }}
      >
        {React.createElement(collapsed ? MenuOutlined : CloseOutlined)}
      </Button>

      <Menu
        mode="inline"
        theme="light"
        openKeys={openSubMenusKeys}
        onOpenChange={onCityOpenChange}
        inlineCollapsed={collapsed}
      >
        <Menu.Item className="search-dir">
          <div id="geocoder-search"></div>
          <div
            onClick={toggles.directions}
            className={`directionsButton ${
              visibility.directions ? 'selected' : ''
            }`}
          >
            <DirectionsIcon />
          </div>
        </Menu.Item>
        <SubMenu
          className={`subMenuFirstStep ${
            isAnyPropertySelected ? 'selected' : ''
          }`}
          key="properties"
          title="Vasakronans fastigheter"
          icon={
            <i
              className={`subMenuIcon ${
                isAnyPropertySelected ? 'selected' : ''
              }`}
            >
              <Pin />
            </i>
          }
        >
          {propertyFilters.map((propFilter) => (
            <Menu.Item
              className={`propertyItem ${
                propFilter.isVisible ? 'selected' : ''
              }`}
              key={propFilter.key}
              onClick={propFilter.toggle}
              icon={<div className={`propertyColor ${propFilter.color}`}></div>}
            >
              {propFilter.name}
              <Checkbox checked={propFilter.isVisible}></Checkbox>
            </Menu.Item>
          ))}
        </SubMenu>
        <SubMenu
          className="subMenuFirstStep"
          key={consts.CitiesKey}
          title="Välj plats"
          icon={
            <i className="subMenuIcon">
              <LocationIcon />
            </i>
          }
        >
          {citisSubmenus.map((citySubmenu) => (
            <SubMenu
              className="citiesSubMenu"
              key={citySubmenu.name}
              onTitleClick={() =>
                flyToClick(citySubmenu.coordinates, citySubmenu.name)
              }
              title={getCityTitle(citySubmenu.name)}
            >
              {buildAreasList(citySubmenu.name)}
            </SubMenu>
          ))}
        </SubMenu>
        <SubMenu
          className={`subMenuFirstStep ${
            visibility.roadsColor || visibility.publicTransport
              ? 'selected'
              : ''
          }`}
          key="communication"
          title="Kommunikationer"
          icon={
            <i
              className={`subMenuIcon ${
                visibility.roadsColor || visibility.publicTransport
                  ? 'selected'
                  : ''
              }`}
            >
              <CommunicationIcon />
            </i>
          }
        >
          {communicationFilters.map((commFilter) => (
            <Menu.Item
              className={`propertyItem ${
                commFilter.isVisible ? 'selected' : ''
              }`}
              key={commFilter.key}
              onClick={commFilter.toggle}
            >
              {commFilter.name}
              <Checkbox checked={commFilter.isVisible}></Checkbox>
            </Menu.Item>
          ))}
        </SubMenu>
        {switches.map((swtch) => (
          <Menu.Item
            className={`switchItem ${swtch.isVisible ? 'selected' : ''}`}
            key={swtch.key}
            onClick={swtch.toggle}
            icon={<i className="subMenuIcon">{swtch.iconComponent}</i>}
          >
            {swtch.name}
            <Switch size="small" checked={swtch.isVisible} />
          </Menu.Item>
        ))}
      </Menu>
    </div>
  );
}

export default SideMenu;
