export function urlAssistant(map) {
  const requestHolder = window.location.href; //window.location.href
  const url = new URL(requestHolder);
  const c = url.searchParams.get('c');
  const a = url.searchParams.get('a');

  const cityNameToZoomAndCoordinate = {
    Stockholm: { zoom: 12.1, center: [18.06, 59.326] },
    stockholm: { zoom: 12.1, center: [18.06, 59.326] },
    sthlm: { zoom: 12.1, center: [18.06, 59.326] },
    Uppsala: { zoom: 13.38, center: [17.645, 59.854] },
    uppsala: { zoom: 13.38, center: [17.645, 59.854] },
    Goteborg: { zoom: 13.57, center: [11.976, 57.707] },
    goteborg: { zoom: 13.57, center: [11.976, 57.707] },
    gbg: { zoom: 13.57, center: [11.976, 57.707] },
    malmo: { zoom: 13.44, center: [13.005, 55.605] },
    Malmo: { zoom: 13.44, center: [13.005, 55.605] },
    lund: { zoom: 12.4, center: [13.206, 55.709] },
    Lund: { zoom: 12.4, center: [13.206, 55.709] },
  };
  if (c) {
    map.flyTo(cityNameToZoomAndCoordinate[c]);
  }

  if (c && a) {
    map.on('style.load', function () {
      function waiting() {
        if (!map.isStyleLoaded()) {
          setTimeout(waiting, 200);
          map.flyTo({ zoom: 5 });
        } else {
          map.flyTo({ zoom: 16, center: [18.074, 59.312] });
        }
      }
      waiting();
    });
  }
}
