import React from 'react';

export default function RetailContentTitleOnly({ propertyname }) {
  return (
    <div className="popUpDiv titleOnly">
      <div className="popUpHeading retail">
        <b>{propertyname || ''}</b>
      </div>
    </div>
  );
}
