import MapboxDirections from '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions';
import '@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions.css';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import axios from 'axios';
import mapboxgl from 'mapbox-gl';
import React, { useEffect, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import ReactGA from 'react-ga';
import './App.css';
import SideMenu from './SideMenu';
import CommerceContent from './TooltipContent/CommerceContent';
import CommerceContentTitleOnly from './TooltipContent/CommerceContentTitleOnly';
import OfficeContent from './TooltipContent/OfficeContent';
import OfficeContentTitleOnly from './TooltipContent/OfficeContentTitleOnly';
import getProjectContent from './TooltipContent/ProjectContent';
import getProjectContentTitleOnly from './TooltipContent/ProjectContentTitleOnly';
import RetailContent from './TooltipContent/RetailContent';
import RetailContentTitleOnly from './TooltipContent/RetailContentTitleOnly';
import * as consts from './consts';
import * as ga from './googleAnalytics';
import * as urlAssistant from './urlAssistant';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const USERNAME = process.env.REACT_APP_MAPBOX_USERNAME;
const DATASET_ID = process.env.REACT_APP_MAPBOX_DATASET_ID;
const ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const ANALYTICS_ID = process.env.REACT_APP_MAPBOX_ANALYTICS_ID; //TEST ID
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
mapboxgl.accessToken = ACCESS_TOKEN;

const featureFilter = (feature) =>
  feature.geometry.type === 'Point' && feature.properties.retail;

function App() {
  const mapContainer = useRef(null);
  const [map, setMap] = useState();

  const [strokVisibility, setStrokVisibility] = useState(false);
  const [commercialAreaVisibility, setCommercialAreaVisibility] = useState(false);
  const [allPropertiesVisibility, setAllPropertiesVisibility] = useState(true);
  const [commerceVisibility, setCommerceVisibility] = useState(false);
  const [officesVisibility, setOfficesVisibility] = useState(false);
  const [projectsVisibility, setProjectsVisibility] = useState(false);
  const [transitVisibility, setTransitVisibility] = useState(true);
  const [roadsColor, setRoadsColor] = useState(false);
  const [isSatelliteView, setSatelliteView] = useState(false);
  const [directionsControl, setDirectionsControl] = useState(null);
  const [pointsVisibility, setPointsVisibility] = useState(true);
  const [clustersVisibility, setClustersVisibility] = useState(true);
  const [features, setFeatures] = useState();
  const [areaCoordinates, setAreaCoordinates] = useState({});
  const [isTilted, setTilt] = useState(false);
  const [pointsPerCity, setPointsPerCity] = useState({});
  const [shopsVisibility, setShopsVisibility] = useState(false);

  useEffect(() => {
    if (!features) return;
    map.on('load', function () {
      map
        .addSource('pointdata', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: features.filter(featureFilter),
          },
          cluster: true,
          clusterMaxZoom: 12,
        })
        .addLayer(consts.clustersLayer)
        .addLayer(consts.clustersLabelLayer);
      // pointfilters();
      consts.allLayersVisibleByDefault.map((allLayersVisible) =>
        map.setLayoutProperty(allLayersVisible, 'visibility', 'visible')
      );
    });
    map.on('mouseenter', consts.clustersLayer.id, function (e) {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', consts.clustersLayer.id, function (e) {
      map.getCanvas().style.cursor = '';
    });

    map.on('click', consts.clustersLayer.id, function (e) {
      const cluster = map.queryRenderedFeatures(e.point, {
        layers: [consts.clustersLayer.id],
      });

      ga.sendClusterEvent(consts.analyticsNames.categoryCluster);

      const toZoom = map.getZoom() >= 12 ? 15 : 12;
      map.flyTo({
        zoom: toZoom,
        center: cluster[0].geometry.coordinates,
        essential: true,
        speed: 0.5,
      });
    });
  }, [features, map]);

  useEffect(() => {
    if (ENVIRONMENT === 'PRODUCTION') {
      ReactGA.initialize(ANALYTICS_ID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    let center = [15.621373, 57.810807];
    let zoom = 6;
    if (isIOS) {
      center = [13.621373, 57.810807];
      zoom = 5;
    }
    setMap(
      new mapboxgl.Map({
        container: mapContainer.current,
        style: process.env.REACT_APP_MAPBOX_STYLE_URL,
        pitch: 0,
        center: center,
        zoom: zoom,
      })
    );
  }, []);

  useEffect(() => {
    if (!map) return;
    const propertiesURL = `https://api.mapbox.com/datasets/v1/${USERNAME}/${DATASET_ID}/features?access_token=${ACCESS_TOKEN}`;
    axios.get(propertiesURL).then(function (data) {
      setFeatures(data.data.features);
    });
  }, [map]);

  useEffect(() => {
    if (!features) return;
    const coordinates = {}; // final object example: {Stockholm: {centrum:[123,456], ...}}
    Object.values(consts.citiesNames).forEach((cityName) => {
      coordinates[cityName] = {};
    });
    const pointsPerCity = {}; // final object example: {Stockholm: 93, ...}
    Object.values(consts.citiesNames).forEach((cityName) => {
      pointsPerCity[cityName] = 0;
    });
    features.filter(featureFilter).forEach((feature) => {
      const area = feature.properties.area;
      const city = feature.properties.city;
      pointsPerCity[city] = pointsPerCity[city] + 1;
      if (area !== undefined && city !== undefined) {
        coordinates[city][area] = feature.geometry.coordinates;
      }
    });
    setAreaCoordinates(coordinates);
    setPointsPerCity(pointsPerCity);
  }, [features]);

  useEffect(() => {
    if (map) {
      map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');

      const searchCtrl = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        placeholder: 'Sök adress',
        layout: {
          'text-size': 16,
        },
      });
      document
        .getElementById('geocoder-search')
        .appendChild(searchCtrl.onAdd(map)).onclick = function () {
        ga.sendClickEvent(consts.menuLabels.labelSearch);
      };

      if (urlAssistant.urlAssistant(map)) {
      }
    }
  }, [map]);
  useEffect(() => {
    if (!map) return;

    function handlePopup(
      layerName,
      TooltipContent,
      isContainingInnerHTML = false
    ) {
      var popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: true,
        closeOnMove: true,
      });
      map.on('closeAllPopups', () => {
        popup.remove();
      });

      map.on('mouseenter', layerName, function (e) {
        //Check if popUpDiv exits and if it does, check if it has the class titleOnly
        if (document.querySelector('.popUpDiv')) {
          if (!document.querySelector('.popUpDiv').classList.contains('titleOnly')) {
            return;
          }
        }

        if (map.getZoom() < 13) return;
        map.getCanvas().style.cursor = 'pointer';
        const coordinates = e.features[0].geometry.coordinates.slice();
        map.fire('closeAllPopups');
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        const properties = e.features[0].properties;
        let tooltipContentInnerHtml;
        if (!isContainingInnerHTML) {
          tooltipContentInnerHtml = renderToString(
            <TooltipContent {...properties} />
          );
        } else {
          // some projects contain html link in the text, this workaround
          // is to avoid using dangerouslySetInnerHTML.
          tooltipContentInnerHtml = TooltipContent(properties);
        }

        ga.sendHoverEvent(properties, layerName);

        popup
          .setLngLat(coordinates)
          .setHTML(tooltipContentInnerHtml)
          .addTo(map);
      });

      map.on('mouseleave', layerName, function (e) {
        map.getCanvas().style.cursor = '';
        // map.fire('closeAllPopups');
      });
    }

    handlePopup(consts.tooltipLayerNames.office, OfficeContentTitleOnly);
    handlePopup(consts.tooltipLayerNames.retail, RetailContentTitleOnly);
    handlePopup(
      consts.tooltipLayerNames.project,
      getProjectContentTitleOnly,
      true
    );
    handlePopup(
      consts.tooltipLayerNames.commerce,
      CommerceContentTitleOnly,
      true
    );
    handlePopup(consts.tooltipLayerNames.officeCircle, OfficeContentTitleOnly);
    handlePopup(consts.tooltipLayerNames.retailCircle, RetailContentTitleOnly);
    handlePopup(
      consts.tooltipLayerNames.projectCircle,
      getProjectContentTitleOnly,
      true
    );
  }, [map]);

  useEffect(() => {
    if (!map) return;

    function handlePopup(
      layerName,
      TooltipContent,
      isContainingInnerHTML = false
    ) {
      var popup = new mapboxgl.Popup({
        closeButton: true,
        closeOnClick: true,
        closeOnMove: true,
      });
      map.on('closeAllPopups', () => {
        popup.remove();
      });

      map.on('click', layerName, function (e) {
        if (map.getZoom() < 13) return;
        map.getCanvas().style.cursor = 'pointer';
        const coordinates = e.features[0].geometry.coordinates.slice();
        map.fire('closeAllPopups');
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        const properties = e.features[0].properties
        let tooltipContentInnerHtml;
        if (!isContainingInnerHTML) {
          tooltipContentInnerHtml = renderToString(
            <TooltipContent {...properties} />
          );
        } else {
          // some projects contain html link in the text, this workaround
          // is to avoid using dangerouslySetInnerHTML.
          tooltipContentInnerHtml = TooltipContent(properties);
        }

        ga.sendHoverEvent(properties, layerName);

        popup
          .setLngLat(coordinates)
          .setHTML(tooltipContentInnerHtml)
          .addTo(map);
      });
      map.on('mouseleave', layerName, function (e) {
        //Check if popUpDiv.titleOnly exits, if so then we know that the popup is not open
        if (!document.querySelector('.popUpDiv.titleOnly')) {
          return;
        }

        map.getCanvas().style.cursor = '';

        if (['VK-Office-Pin', 'VK-Strok-Pin', 'VK-Retail-Pin', 'VK-Office-Circle', 'VK-Retail-Circle', 'VK-Project-Pin'].includes(layerName)) {
          map.fire('closeAllPopups');
        }
      });
    }

    handlePopup(consts.tooltipLayerNames.office, OfficeContent);
    handlePopup(consts.tooltipLayerNames.retail, RetailContent);
    handlePopup(consts.tooltipLayerNames.project, getProjectContent, true);
    handlePopup(consts.tooltipLayerNames.commerce, CommerceContent, true);
    handlePopup(consts.tooltipLayerNames.officeCircle, OfficeContent);
    handlePopup(consts.tooltipLayerNames.retailCircle, RetailContent);
    handlePopup(
      consts.tooltipLayerNames.projectCircle,
      getProjectContent,
      true
    );
  }, [map]);

  const handleDirectionsClick = () => {
    ga.sendClickEvent(consts.menuLabels.labelDirections);
    if (!directionsControl) {
      const dirCtrl = new MapboxDirections({
        accessToken: mapboxgl.accessToken,
        unit: 'metric',
        interactive: false,
        profile: 'mapbox/driving',
      });
      setDirectionsControl(dirCtrl);
      map.addControl(dirCtrl, 'top-left');
    } else {
      map.removeControl(directionsControl);
      setDirectionsControl(null);
    }
  };

  const handleSatelliteClick = () => {
    ga.sendClickEvent(consts.menuLabels.labelSatellite);
    const roadLayerNames = [
      consts.layerNames.roadStreet,
      consts.layerNames.roadMinor,
      consts.layerNames.bridgePedestrian,
      consts.layerNames.bridgePath,
      consts.layerNames.roadPrimary,
      consts.layerNames.bridgeStreetMinor,
    ];
    toggleVisibility(
      consts.layerNames.satellite,
      isSatelliteView,
      setSatelliteView
    );
    roadLayerNames.map((roadLayerName) =>
      map.setLayoutProperty(
        roadLayerName,
        'visibility',
        isSatelliteView ? 'visible' : 'none'
      )
    );

    paintRoads(roadsColor, !isSatelliteView);
  };
  const handleTiltClick = () => {
    ga.sendClickEvent(consts.menuLabels.labelTilt);
    if (isTilted) {
      setTilt(false);
      map.flyTo({
        pitch: 0,
      });
    } else {
      setTilt(true);
      map.flyTo({
        pitch: 60,
      });
    }
  };
  const handleFlyToClick = (coordinates, cityName) => {
    if (cityName) {
      ga.sendClickEvent(cityName);
    }
    map.flyTo({
      zoom: 15.05,
      center: coordinates,
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
    });
  };

  const handleStrokClick = () => {
    ga.sendClickEvent(consts.menuLabels.labelRetail);
    toggleVisibility(
      consts.layerNames.strok,
      strokVisibility,
      setStrokVisibility
    );
  };
  const handleCommercialAreaClick = () => {
    ga.sendClickEvent(consts.menuLabels.labelCommercialArea);
    toggleVisibility(
      consts.layerNames.commercialArea,
      commercialAreaVisibility,
      setCommercialAreaVisibility
    );
  };
  const handlePointsClick = () => {
    ga.sendClickEvent(consts.menuLabels.labelMarkers);
    Object.values(consts.tooltipLayerNames).map((layerName) =>
      toggleVisibility(layerName, pointsVisibility, setPointsVisibility)
    );
    toggleVisibility(
      consts.clustersLayer.id,
      clustersVisibility,
      setClustersVisibility
    );
  };
  const handleClustersClick = () => {
    toggleVisibility(
      consts.clustersLayer.id,
      clustersVisibility,
      setClustersVisibility
    );
  };
  const handleShopsClick = () => {
    ga.sendClickEvent(consts.menuLabels.labelShops);
    toggleVisibility(
      consts.layerNames.shopsPoint,
      shopsVisibility,
      setShopsVisibility
    );
  };
  const handleCommerceClick = () => {
    ga.sendClickEvent(consts.menuLabels.labelRetail);
    toggleVisibility(
      consts.layerNames.retail,
      commerceVisibility,
      setCommerceVisibility
    );
  };
  const handleAllPropertiesClick = () => {
    ga.sendClickEvent(consts.menuLabels.labelAll);
    toggleVisibility(
      consts.layerNames.all,
      allPropertiesVisibility,
      setAllPropertiesVisibility
    );
  };

  const handleProjectsClick = () => {
    ga.sendClickEvent(consts.menuLabels.labelProject);
    toggleVisibility(
      consts.layerNames.project,
      projectsVisibility,
      setProjectsVisibility
    );
  };
  const handleOfficesClick = () => {
    ga.sendClickEvent(consts.menuLabels.labelOffice);
    toggleVisibility(
      consts.layerNames.office,
      officesVisibility,
      setOfficesVisibility
    );
  };

  const handlePublicTransportationClick = () => {
    ga.sendClickEvent(consts.menuLabels.labelTransit);
    toggleVisibility(
      consts.layerNames.transit,
      transitVisibility,
      setTransitVisibility
    );
  };

  const handleRoadsClick = () => {
    ga.sendClickEvent(consts.menuLabels.labelRoads);
    paintRoads(!roadsColor, isSatelliteView);
    setRoadsColor(!roadsColor);
  };

  function paintRoads(shouldBeColored, shouldBeSatellite) {
    const layerNames = [consts.layerNames.road, consts.layerNames.bridge];
    if (shouldBeColored) {
      layerNames.map((layerName) =>
        map.setPaintProperty(layerName, 'line-color', '#ffa35c')
      );
    } else if (shouldBeSatellite) {
      layerNames.map((layerName) =>
        map.setPaintProperty(layerName, 'line-color', 'transparent')
      );
    } else {
      layerNames.map((layerName) =>
        map.setPaintProperty(layerName, 'line-color', '#ffffff')
      );
    }
  }

  function toggleVisibility(layerName, layerVisibility, layerVisibilitySetter) {
    layerVisibilitySetter(!layerVisibility);

    map.setLayoutProperty(
      layerName,
      'visibility',
      layerVisibility ? 'none' : 'visible'
    );
  }
  return (
    <div id="app">
      <div ref={mapContainer} className="mapContainer">
        <SideMenu
          toggles={{
            directions: handleDirectionsClick,
            commerce: handleCommerceClick,
            projects: handleProjectsClick,
            offices: handleOfficesClick,
            strok: handleStrokClick,
            commercialArea: handleCommercialAreaClick,
            allProperties: handleAllPropertiesClick,
            publicTransport: handlePublicTransportationClick,
            roads: handleRoadsClick,
            points: handlePointsClick,
            satellite: handleSatelliteClick,
            tilt: handleTiltClick,
            shops: handleShopsClick,
            clusters: handleClustersClick,
          }}
          visibility={{
            directions: directionsControl,
            commerce: commerceVisibility,
            projects: projectsVisibility,
            offices: officesVisibility,
            strok: strokVisibility,
            commercialArea: commercialAreaVisibility,
            allProperties: allPropertiesVisibility,
            publicTransport: transitVisibility,
            roadsColor: roadsColor,
            points: pointsVisibility,
            clusters: clustersVisibility,
            satellite: isSatelliteView,
            tilt: isTilted,
            shops: shopsVisibility,
          }}
          flyToClick={handleFlyToClick}
          coordinates={areaCoordinates}
          pointsPerCity={pointsPerCity}
        />
      </div>
    </div>
  );
}
export default App;
