export const clustersLayer = {
  id: 'clusters',
  type: 'circle',
  source: 'pointdata',
  filter: ['has', 'point_count'],
  paint: {
    'circle-opacity': 1,
    'circle-color': '#0014CC',
    'circle-radius': ['step', ['get', 'point_count'], 16, 2, 20, 50, 22],
  },
};

export const clustersLabelLayer = {
  id: 'cluster-label',
  type: 'symbol',
  source: 'pointdata',
  layout: {
    'text-field': '{point_count}',
  },
  paint: {
    'text-color': '#FFFFFF',
  },
};
export const analyticsNames = {
  categoryPopOver: 'Popover',
  categoryMenu: 'Menu',
  categoryCluster: 'Cluster',
  actionClick: 'click',
  actionHover: 'hover',
  collapsed: 'Collapsed menu',
};

export const tooltipLayerNames = {
  office: 'VK-Office-Pin',
  retail: 'VK-Retail-Pin',
  project: 'VK-Project-Pin',
  commerce: 'VK-Strok-Pin',
  officeCircle: 'VK-Office-Circle',
  retailCircle: 'VK-Retail-Circle',
  projectCircle: 'VK-Project-Circle',
};
export const allLayersVisibleByDefault = [
  'VK-Office-Pin',
  'VK-Retail-Pin',
  'VK-Project-Pin',
  'VK-Strok-Pin',
  'VK-Office-Circle',
  'VK-Retail-Circle',
  'VK-Project-Circle',
  'VK-Properties-All',
];

export const layerNames = {
  strok: 'VK-Strok',
  commercialArea: 'VK-Commercial-Area',
  retail: 'VK-Properties-Retail',
  all: 'VK-Properties-All',
  project: 'VK-Properties-Project',
  office: 'VK-Properties-Office',
  transit: 'transit-label',
  road: 'road-secondary-tertiary',
  bridge: 'bridge-primary-secondary-tertiary',
  roadStreet: 'road-street',
  roadPrimary: 'road-primary',
  bridgeStreetMinor: 'bridge-street-minor',
  roadMinor: 'road-minor',
  bridgePedestrian: 'bridge-pedestrian',
  bridgePath: 'bridge-path',
  satellite: 'mapbox-satellite',
  shopsPoint: 'poi-label',
};
export const menuLabels = {
  labelAll: 'Alla',
  labelOffice: 'Kontorsfastigheter',
  labelProperties: 'Handelsfastigheter',
  labelProject: 'Projektfastigheter',
  labelRetail: 'Handelsstråk',
  labelCommercialArea: 'Område',
  labelTransit: 'Kollektivtrafik',
  labelRoads: 'Huvudvägar',
  labelMarkers: 'Visa Markörer',
  labelSatellite: 'Satellitvy',
  labelTilt: 'Tilta kartan',
  labelShops: 'Butiker i närområdet',
  labelDirections: 'Directions',
  labelSearch: 'search',
};

export const citiesNames = {
  Malmo: 'Malmö',
  Stockholm: 'Stockholm',
  Gothenburg: 'Göteborg',
  Uppsala: 'Uppsala',
};

export const CitiesKey = 'cities';

export const CitiesCoordinates = {
  Malmo: [13.005, 55.605],
  Stockholm: [18.066635, 59.331966],
  Gothenburg: [11.966739, 57.705698],
  Uppsala: [17.645, 59.854],
};
