import * as consts from './consts';
import ReactGA from 'react-ga';

export function sendClickEvent(label) {
  ReactGA.event({
    category: consts.analyticsNames.categoryMenu,
    action: consts.analyticsNames.actionClick,
    label: label,
  });
}

export function sendHoverEvent(properties, layerName) {
  let label;
  if (
    layerName === consts.tooltipLayerNames.retail ||
    consts.tooltipLayerNames.office
  ) {
    label = properties.propertyname;
  }
  if (
    layerName === consts.tooltipLayerNames.retailCircle ||
    consts.tooltipLayerNames.officeCircle
  ) {
    label = properties.propertyname;
  }
  if (layerName === consts.tooltipLayerNames.project) {
    label = properties.title;
  }
  if (layerName === consts.tooltipLayerNames.projectCircle) {
    label = properties.title;
  }
  if (layerName === consts.tooltipLayerNames.commerce) {
    label = properties.strokName;
  }
  ReactGA.event({
    category: consts.analyticsNames.categoryPopOver,
    action: consts.analyticsNames.actionHover,
    label: label,
  });
}
export function sendClusterEvent(label) {
  ReactGA.event({
    category: consts.analyticsNames.categoryCluster,
    action: consts.analyticsNames.actionClick,
    label: label,
  });
}
