import React from 'react';

export default function OfficeContent({ address, propertyname, built }) {
  return (
    <div className="popUpDiv">
      <div className="popUpHeading office">
        <b>{propertyname || ''}</b>
      </div>
      <div className="popUpInfo">
        <div className="address">
          <p className="addressParagraph">Adress</p>
          {address || ''}
        </div>
        <div className="built">
          <p className="builtParagraph">
            Byggnadsår
            <br />
            ombyggnadsår
          </p>
          {built || ''}
        </div>
      </div>
    </div>
  );
}
